import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import { Box, Grid, Typography, CircularProgress, Card, Button } from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {ImageSchema, MeetingSchema} from 'app/models/booking.model'
import {useOnView} from 'app/providers/onview.provider'
import 'modules/booking/css/booking.scss'
import BookingListFilterComponent from "modules/booking/components/booking/bookingListFilter.component";
import BookingListCardComponent from "modules/booking/components/booking/bookingListCard.component";
import {useApp} from "app/providers/app.provider";
import {useOnLogin} from "app/providers/onlogin.provider";
import DefaultImg from "images/booking/default.jpg";
import {ServiceTypeEnum} from "app/enums/booking.enum";
import BookingHeaderComponent from "modules/booking/components/booking/bookingHeader.component";
import { useNomadeCounter } from 'app/hooks/useNomadeCounter'

const Booking = (): JSX.Element => {
    const { bookingHour, bookingServiceType, bookingDate, fromApp } = useApp()
    const {t} = useTranslation()
    const { meetingRoomsUseMutation, getAssets } = useOnView()
    const [meetingRooms, setMeetingRooms] = useState<MeetingSchema[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [offset, setOffset] = useState<number>(0)
    const [isLast, setIsLast] = useState<boolean>(true)
    const firstRender = useRef(true)
    const [isValidDate, setIsValidDate] = useState<boolean>(true)
    const {userCenterId, centers, currentRole, currentEnterprise} = useOnLogin()
    const centerIds = useMemo(() => centers?.map(center => center.id) ?? [], [centers]);
    const [targetCenter, setTargetCenter] = useState<string>(String(userCenterId))
    const { nomadeCounter } = useNomadeCounter(userCenterId, currentEnterprise, undefined);
    const [isNomadeCounterValid, setIsNomadeCounterValid] = useState(false);
    const [serviceImages, setServicesImages] = useState<ImageSchema[]>( [{
        url : DefaultImg,
        name : 'image',
        tags : [],
        id : 0
    }]);

    const bookingLinkOffice = useMemo<string>(
        () => (fromApp ? '/booking-office?fromApp=1' : '/booking-office'),
        [fromApp]
    )
    const currentHour = new Date().getHours();
    const begin = bookingDate && bookingHour.begin !== '0' && bookingHour.end !== '0' && isValidDate ? `${bookingDate}T${bookingHour.begin}:00:00` : '';
    const end = bookingDate && bookingHour.begin !== '0' && bookingHour.end !== '0' && isValidDate ? `${bookingDate}T${bookingHour.end}:00:00` : '';
    const centerId = centerIds.includes(Number(targetCenter)) ? Number(targetCenter) : 0;
    const initialBegin = currentHour < 17 ? String(currentHour + 1).padStart(2, '0') : "09";
    const initialEnd = String(Number(initialBegin) + 1).padStart(2, '0');

    useEffect(() => {
        if (nomadeCounter?.countReservation !== undefined) {
            setIsNomadeCounterValid(true);
        }
    }, [nomadeCounter]);

    useEffect(() => {
        if(!firstRender.current) {
            getMeetingRooms(true).then();
        }
    }, [targetCenter, bookingServiceType, bookingDate, bookingHour, isValidDate])

    useEffect(() => {
        if(centerIds.length > 0) {
            firstRender.current = false
            getInitialMeetingRooms().then();
        }
    }, [centerIds])

    const getInitialMeetingRooms = useCallback(async () => {
        setIsLoading(true)
        const response = await meetingRoomsUseMutation?.mutateAsync({
            queryParams: { centerId: centerIds.includes(Number(targetCenter)) ? Number(targetCenter) : undefined, begin: begin, end: end }
        });

        setOffset(response!.actual);
        setMeetingRooms(response!.items);
        setIsLast(response!.last);

        if(response!.items) {
            const images = await getAssets?.mutateAsync({ type: 'service-types', id: Number(response!.items[0].serviceType) });
            if (images?.length) setServicesImages(images);
        }

        setIsLoading(false);
    }, [meetingRoomsUseMutation, targetCenter, centerIds])

    const getMeetingRooms = useCallback(async (reset: boolean) => {
        if(reset) setMeetingRooms([]);

        setIsLoading(true);
        const response = await meetingRoomsUseMutation?.mutateAsync({
            queryParams: { offset: reset ? 0 : offset, centerId: centerId || undefined, serviceType: bookingServiceType, begin: begin, end: end }
        });

        setOffset(response!.actual);
        setMeetingRooms((prev) => reset ? response!.items : [...prev, ...response!.items]);
        setIsLast(response!.last);

        if(response!.items) {
            const images = await getAssets?.mutateAsync({ type: 'service-types', id: Number(response!.items[0].serviceType) });
            if (images?.length) setServicesImages(images);
        }

        setIsLoading(false);
    }, [meetingRoomsUseMutation, offset, targetCenter, bookingServiceType, bookingDate, bookingHour, isValidDate, centerIds])

    const refreshData = useCallback(() => {
        if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight && !isLoading && !isLast) {
            getMeetingRooms(false);
        }
    }, [isLoading, isLast, getMeetingRooms])

    useEffect(() => {
        window.addEventListener("scroll", refreshData)
        return () => window.removeEventListener('scroll', refreshData);
    }, [refreshData])

    const bookingListLink = useMemo(() => (fromApp ? '/booking_list?fromApp=1' : '/booking_list'), [fromApp]);

    return (
        <>
            {fromApp && <Grid item xs={12} md={12}  style={{marginBottom : 12}}>
                <Card>
                    <BookingHeaderComponent bookingListLink={bookingListLink}/>
                </Card>
            </Grid>}
            <Box>
                {!(currentRole === 'ROLE_USER' && (!isNomadeCounterValid || (isNomadeCounterValid && nomadeCounter?.nomadeLabel === '100% Nomade France'))) && (
                  <Typography variant={'caption'} color={'textPrimary'} gutterBottom>
                      {t('common.booking_open_desktop_instead')}
                      <Button variant={'text'} color={'primary'} size={'small'} style={{fontSize : 'inherit', textTransform :'initial'}} href={bookingLinkOffice}>
                          {t('common.click_here_maj')}
                      </Button>
                  </Typography>
                )}
                <Typography variant="h2" color={'textPrimary'} gutterBottom>
                    {t('common.bookingView.onlineBooking')}
                </Typography>
                <BookingListFilterComponent
                    mode={ServiceTypeEnum.MEETING}
                    centerIds={centerIds}
                    targetCenter={targetCenter}
                    setTargetCenter={setTargetCenter}
                    isValidDate={isValidDate}
                    setIsValidDate={setIsValidDate}
                    defaultBeginHour={initialBegin}
                    defaultEndHour={initialEnd}
                />
            </Box>
            <Grid container spacing={3} style={{marginTop: 5}}>
                {!isLoading && meetingRooms.length > 0 && meetingRooms.map(meetingRoom => (
                    <BookingListCardComponent key={meetingRoom.id} meetingRoom={meetingRoom} images={serviceImages} bookingDate={bookingDate} />
                ))}
                {isLoading && (
                    <Box width={'100%'} textAlign={'center'} marginTop={5}>
                        <CircularProgress color="primary" />
                    </Box>
                )}
            </Grid>
        </>
    );
};

export default Booking;
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import {ICenter} from "app/models/centers.model";
import {ServiceTypeEnum} from "app/enums/booking.enum";
import React, {useMemo, useState, Dispatch, useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useOnLogin} from "app/providers/onlogin.provider";
import {useApp} from "app/providers/app.provider";

interface IProps {
    mode : number,
    centerIds: number[]
    targetCenter: string
    setTargetCenter: Dispatch<React.SetStateAction<string>>
    isValidDate: boolean
    setIsValidDate: Dispatch<React.SetStateAction<boolean>>
    defaultBeginHour?: string
    defaultEndHour?: string
}

const BookingListFilterComponent = ({
        mode,
        centerIds,
        targetCenter,
        setTargetCenter,
        isValidDate,
        setIsValidDate,
        defaultBeginHour,
        defaultEndHour,
}: IProps) => {
  const {
      bookingHour,
      setBookingHour,
      bookingHours,
      bookingDateMinAndMax,
      bookingDate,
      setBookingDate
  } = useApp()

  const {t} = useTranslation()
  const {centers} = useOnLogin()
  const minDate = useMemo(() => bookingDateMinAndMax.min, [bookingDateMinAndMax.min])
  const maxDate = useMemo(() => bookingDateMinAndMax.max(), [bookingDateMinAndMax.max])
  const [endHours, setEndHours] = useState<string[]>([])
  const currentHour = new Date().getHours();
  const currentDate = new Date().toISOString().split('T')[0];
  const filteredBookingHours = bookingDate === currentDate ? bookingHours.filter(hour => Number(hour) > currentHour) : [];

  const handleDateChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      const date = event.target.value
      setIsValidDate(date !== '')
      setBookingDate(date)

      if (date !== currentDate) {
        setBookingHour({ begin: bookingHours[0], end: bookingHours[1] });

      } else {
        const beginHour = String(currentHour + 1).padStart(2, '0');
        const endHour = String(beginHour + 1).padStart(2, '0');
        setBookingHour({ begin: beginHour, end: endHour });
      }
  }, [setBookingDate, setIsValidDate, setBookingHour])

  const handleBeginChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const beginHour = String(event.target.value);
    const endHour = String(Number(beginHour) + 1).padStart(2, '0');
    setBookingHour(prevState => ({...prevState, end: endHour, begin: beginHour}));
  };

  useEffect(() => {
    if (bookingDate === currentDate) {
      if (bookingHour.begin !== '0') {
        const beginIndex = filteredBookingHours.indexOf(bookingHour.begin);
        if (beginIndex !== -1) {
          const newEndHours = filteredBookingHours.slice(beginIndex + 1);
          newEndHours.push('18');
          setEndHours(newEndHours);
        }
      } else {
        setEndHours([]);
      }
    } else {
      if (bookingHour.begin !== '0') {
        const beginIndex = bookingHours.indexOf(bookingHour.begin);
        if (beginIndex !== -1) {
          const newEndHours = bookingHours.slice(beginIndex + 1);
          newEndHours.push('18');
          setEndHours(newEndHours);
        }
      } else {
        setEndHours([]);
      }
    }
  }, [bookingHour.begin, bookingHours, bookingDate]);

  useEffect(() => {
    if (bookingHour.begin === '0' && bookingHour.end === '0' && defaultBeginHour && defaultEndHour) {
      setBookingHour({ begin: defaultBeginHour, end: defaultEndHour });
    }
  }, [defaultBeginHour, defaultEndHour, setBookingHour, bookingHour]);

    return (
        <Grid container spacing={3} style={{marginTop: 5}}>
            {mode === ServiceTypeEnum.MEETING && (
              <Grid item xs={12} sm={'auto'}>
                <FormControl variant="outlined" fullWidth size={'small'}>
                    <InputLabel id="center-filter">{t('common.global.center')}</InputLabel>
                    <Select
                        labelId="center-filter"
                        value={centerIds.includes(Number(targetCenter)) ? targetCenter : '0'}
                        onChange={(event) => setTargetCenter(String(event.target.value))}
                        label={t('common.global.center')}
                    >
                        <MenuItem value="0">
                            <em>{t('common.bookingView.filters.allCenters')}</em>
                        </MenuItem>
                        {centers?.map((center: ICenter) => (
                            <MenuItem key={center.id} value={center.id}>{center.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={12} sm={'auto'}>
                <TextField
                    fullWidth
                    id={'date-filter'}
                    variant="outlined"
                    label={t('common.date')}
                    type="date"
                    size="small"
                    value={bookingDate}
                    onChange={handleDateChange}
                    inputProps={{ min: minDate, max: maxDate }}
                    InputLabelProps={{ shrink: true }}
                    error={!isValidDate}
                />
            </Grid>
            <Grid item xs={12} sm={'auto'}>
                <FormControl variant="outlined" fullWidth size={'small'}>
                    <InputLabel id="begin-filter">
                      {t('common.booking_select_hour_start')}
                    </InputLabel>
                    <Select
                        style={{minWidth : "120px"}}
                        labelId="begin-filter"
                        value={bookingHour.begin}
                        onChange={handleBeginChange}
                        label={t('common.booking_select_hour_start')}
                    >
                        <MenuItem value="0" style={{ userSelect: 'none', pointerEvents: 'none' }}>
                            <em>{t('common.booking_select_hour_start')}</em>
                        </MenuItem>
                        {(filteredBookingHours.length > 0 ? filteredBookingHours : bookingHours).map((hour: string) => (
                          <MenuItem key={hour} value={hour}>{`${hour}h`}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={'auto'}>
                <FormControl variant="outlined" fullWidth size={'small'}>
                    <InputLabel id="end-filter">{t('common.booking_select_hour_end')}</InputLabel>
                    <Select
                        style={{minWidth : "120px"}}
                        labelId="end-filter"
                        value={bookingHour.end}
                        onChange={(event) => setBookingHour(prevState => ({...prevState, end: String(event.target.value)}))}
                        label={t('common.booking_select_hour_end')}
                    >
                        <MenuItem value="0" style={{ userSelect: 'none', pointerEvents: 'none' }}>
                            <em>{t('common.booking_select_hour_end')}</em>
                        </MenuItem>
                        {endHours.map((hour: string) => (
                            <MenuItem key={hour} value={hour}>{`${hour}h`}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    )
}

export default BookingListFilterComponent

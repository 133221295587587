import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, Button, CircularProgress, IconButton, InputAdornment, Chip } from "@material-ui/core";
import { Search as SearchIcon, Close as CloseIcon } from '@material-ui/icons';
import { useOnLogin } from 'app/providers/onlogin.provider';
import 'modules/appointments/css/appointment.scss';
import { IGuestData, IGuest, IAppointmentData } from 'app/models/appointment.model'
import { ICenter } from 'app/models/centers.model';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useOnView } from 'app/providers/onview.provider';

const AppointmentAddClientView = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userCenterId, centers, account } = useOnLogin();
  const { appointmentCreateUseMutation, guestSearchUseMutation, guestAddUseMutation } = useOnView();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [appointmentData, setAppointmentData] = useState<IAppointmentData>({
    organiser: account?.id || 0,
    center: userCenterId || 0,
    guests: [] as IGuest[],
    description: '',
    beginDate: dayjs().startOf('hour'),
    endDate: dayjs().startOf('hour').add(1, 'hour'),
  });

  const [guestForm, setGuestForm] = useState<IGuestData>({ email: '', firstname: '', lastname: '', mobile: '', appointmentCenter: userCenterId || 0 });
  const [isGuestFound, setIsGuestFound] = useState<boolean>(false);
  const [isGuestSearchCompleted, setIsGuestSearchCompleted] = useState<boolean>(false);

  const handleInputChange = (name: string, value: any) => {
    setAppointmentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleGuestInputChange = (name: string, value: any) => {
    setGuestForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const handleSearchGuest = async () => {
    const email = guestForm?.email;
    if (email && isValidEmail(email)) {
      const guestData = await guestSearchUseMutation?.mutateAsync(email);

      if (guestData) {
        const guestExists = appointmentData.guests.some(guest => guest.id === guestData.id);
        if (!guestExists) {
          setAppointmentData(prevData => ({
            ...prevData,
            guests: [...prevData.guests, { id: guestData.id, name: guestData.name }],
          }));
        }
        setIsGuestFound(true);
        setGuestForm({ email: '', firstname: '', lastname: '', mobile: '', appointmentCenter: userCenterId || 0 });
      } else {
        setIsGuestFound(false);
      }
      setIsGuestSearchCompleted(true);
    }
  };

  const handleAddNewGuest = async () => {
    if (guestForm) {
      const newGuestData = await guestAddUseMutation?.mutateAsync(guestForm);

      if (newGuestData) {
        setAppointmentData((prevData) => ({
          ...prevData,
          guests: [...prevData.guests, { id: newGuestData.id, name: newGuestData.name }],
        }));
        setGuestForm({ email: '', firstname: '', lastname: '', mobile: '', appointmentCenter: userCenterId || 0 });
        setIsGuestSearchCompleted(false);
      }
    }
  };

  const handleCancelGuestAdd = () => {
    setGuestForm({ email: '', firstname: '', lastname: '', mobile: '', appointmentCenter: userCenterId || 0 });
    setIsGuestSearchCompleted(false);
    setIsGuestFound(false);
  };

  const handleDeleteGuest = (index: number) => {
    setAppointmentData((prevData) => ({
      ...prevData,
      guests: prevData.guests.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = useCallback(async () => {
    if (appointmentData.center && appointmentData.beginDate && appointmentData.endDate && appointmentData.guests.length) {
      setIsLoading(true);
      try {
        await appointmentCreateUseMutation?.mutateAsync({
          ...appointmentData,
          guests: appointmentData.guests.map(guest => guest?.id)
        })
      }finally {
        setIsLoading(false);
        navigate('/appointments')
      }
    }
  }, [appointmentData]);

  useEffect(() => {
    if (appointmentData.beginDate.isAfter(appointmentData.endDate)) {
      setAppointmentData((prevData) => ({
        ...prevData,
        endDate: appointmentData.beginDate.add(1, 'hour'),
      }));
    }
  }, [appointmentData.beginDate, appointmentData.endDate]);

  useEffect(() => {
    if (account?.id) {
      setAppointmentData((prevData) => ({
        ...prevData,
        center: userCenterId || 0,
        organiser: account?.id || 0,
      }));
    }
  }, [account, userCenterId]);

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="myCard myProfile">
      <div className="card-header">
        <Typography variant="h2" color="primary">
          {t('common.appointment.add_appointment')}
        </Typography>
      </div>
      <div className="card-content">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <label>{t('common.appointment.select_center')}</label>
          </Grid>

          <Grid item xs={12}>
            <FormControl variant="outlined" fullWidth size="small">
              <InputLabel id="center-filter">{t('common.global.center')}</InputLabel>
              <Select
                labelId="center-filter"
                value={appointmentData.center || '0'}
                onChange={(event) => handleInputChange('center', event.target.value)}
                label={t('common.global.center')}
                required
              >
                {centers !== undefined &&
                  centers.map((center: ICenter) => (
                    <MenuItem key={center.id} value={center.id}>
                      {center.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <label>{t('common.appointment.select_date')}</label>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth size="small">
              <TextField
                variant="outlined"
                size={'small'}
                fullWidth
                label={t('common.begin')}
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleInputChange('beginDate', dayjs(event.target.value))
                }
                value={appointmentData.beginDate ? appointmentData.beginDate.format('YYYY-MM-DDTHH:mm') : ''}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" fullWidth size="small">
              <TextField
                variant="outlined"
                size={'small'}
                fullWidth
                label={t('common.end')}
                type="datetime-local"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleInputChange('endDate', dayjs(event.target.value))
                }
                value={appointmentData.endDate ? appointmentData.endDate.format('YYYY-MM-DDTHH:mm') : ''}
                required
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <label>{t('common.appointment.add_guests')}</label>
          </Grid>

          <Grid item xs={12} sm={6} lg={3}>
            <TextField
              label={t('common.email')}
              variant="outlined"
              size="small"
              fullWidth
              value={guestForm?.email || ''}
              onChange={(e) => handleGuestInputChange('email', e.target.value)}
              disabled={isGuestSearchCompleted && !isGuestFound}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleSearchGuest}
                      disabled={!guestForm?.email || !isValidEmail(guestForm.email) || (isGuestSearchCompleted && !isGuestFound)}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {isGuestSearchCompleted && !isGuestFound && (
            <>
              <Grid item xs={12} sm={6} lg={3}>
                <TextField
                  label={t('common.firstname')}
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={guestForm?.firstname || ''}
                  onChange={(e) => handleGuestInputChange('firstname', e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={3}>
                <TextField
                  label={t('common.lastname')}
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={guestForm?.lastname || ''}
                  onChange={(e) => handleGuestInputChange('lastname', e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={2}>
                <TextField
                  label={t('common.mobile_phone')}
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={guestForm?.mobile || ''}
                  onChange={(e) => handleGuestInputChange('mobile', e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} lg={1} className={'card-actions-guest'}>
                <Button
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={handleAddNewGuest}
                  disabled={!guestForm?.firstname || !guestForm?.lastname || !guestForm?.mobile}
                >
                  {t('common.add')}
                </Button>
                <IconButton
                  onClick={handleCancelGuestAdd}
                  aria-label="cancel"
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <div>
              {appointmentData.guests.map((guest, index) => (
                <Chip
                  key={index}
                  label={guest.name}
                  onDelete={() => handleDeleteGuest(index)}
                  color="primary"
                  style={{ margin: '5px' }}
                />
              ))}
            </div>
          </Grid>

          <Grid item xs={12}>
            <label>{t('common.appointment.appointment_description')}</label>
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              size={'small'}
              fullWidth
              label={t('common.appointment.description')}
              multiline
              rows={2}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                handleInputChange('description', event.target.value)
              }
              value={appointmentData.description}
            />
          </Grid>
        </Grid>
      </div>

      {isLoading && (
        <div className="myCardLoader">
          <CircularProgress color="secondary" />
        </div>
      )}

      {!isLoading && (
        <div className="card-actions-appointment">
          <Link to={'/appointments'}>
            <Button type="button" variant="outlined" color="primary">
              {t('common.cancel')}
            </Button>
          </Link>
          <Button
            type="button"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={
              !appointmentData.center ||
              !appointmentData.beginDate ||
              !appointmentData.endDate ||
              !appointmentData.guests.length
            }
          >
            {t('common.save')}
          </Button>
        </div>
      )}
    </div>
  );
};

export default AppointmentAddClientView;

import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { CheckCircle, Cancel, AccessTime } from '@material-ui/icons'
import { GuestStatusEnum, IAppointmentListItem, StatusEnum } from 'app/models/appointment.model'
import Typography from '@material-ui/core/Typography'
import {
  Box,
  Chip,
  List,
  ListItem,
  Tooltip
} from '@material-ui/core'
import Collapse from '@material-ui/core/Collapse'
import 'modules/appointments/css/appointment.scss'
import Button from '@material-ui/core/Button'
import ConfirmModal from 'modules/appointments/components/ConfirmModal'
import { useOnView } from 'app/providers/onview.provider'

export interface IProps {
  index: number
  openCollapseStatus: number[]
  appointment: IAppointmentListItem
  setOpen: (index: number) => void
  onChange: () => void
}

const appointmentListItem = ({
    index,
    openCollapseStatus,
    appointment,
    setOpen,
    onChange,
  }: IProps): JSX.Element => {

  const {cancelAppointmentUseMutation} = useOnView()

  const {t} = useTranslation()

  const [modalOpen, setModalOpen] = useState<boolean>(false)

  function formatHourDate(begin: string, end: string) {
    // Create Date objects
    const beginDate = new Date(begin);
    const endDate = new Date(end);

    const timeFormatter = new Intl.DateTimeFormat('fr-FR', {
      hour: '2-digit',
      minute: '2-digit',
    });

    const formattedBeginTime = timeFormatter.format(beginDate);
    const formattedEndTime = timeFormatter.format(endDate);

    // Combine date and times into the final string
    return `${formattedBeginTime} à ${formattedEndTime}`;
  }

  function formatDayDate(begin: string, end: string) {
    // Create Date objects
    const beginDate = new Date(begin);
    const endDate = new Date(end);

    // Set up formatter for the date and time in French
    const dateFormatter = new Intl.DateTimeFormat('fr-FR', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });

    // Format the date and times
    const beginFormattedDate = dateFormatter.format(beginDate);
    const endFormattedDate = dateFormatter.format(endDate);

    // Combine date and times into the final string
    return beginFormattedDate === endFormattedDate ? `${beginFormattedDate}` :
      `${beginFormattedDate} - ${endFormattedDate}`;
  }

  function openConfirmModal(e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    setModalOpen(true);
  }

  function cancelAppointment() {
    setModalOpen(false);
    cancelAppointmentUseMutation?.mutateAsync(appointment.id).then(() => {
      appointment.status = StatusEnum.CANCELLED as unknown as string
      onChange();
    }).catch();
  }

  return (
    <div className="card-list mb-20 appointmentList" key={index}>
      <ConfirmModal
        message={t('common.appointment.confirmCancel')}
        open={modalOpen}
        onConfirm={() => cancelAppointment()}
        onCancel={() => setModalOpen(false)} />
      <div
        onClick={() => { setOpen(index) }}
        aria-expanded={openCollapseStatus.includes(index)}
        className={
          openCollapseStatus.includes(index) ? 'card-list-header open' : 'card-list-header'
        }>
        <div className="block-list icon">
          {+appointment.status === StatusEnum.SCHEDULED
            && <CheckCircle className="statusIcon" data-toggle={"tooltip"} />}
          {+appointment.status === StatusEnum.CONFIRMED
            && <CheckCircle className="confirmedIcon" />}
          {+appointment.status === StatusEnum.CANCELLED
            && <Cancel className="cancelledIcon" />}
        </div>
        <div className="block-list date">
          <Typography
            variant="overline"><strong>{appointment.statusLabel}</strong>
          </Typography>
          <div className="appointment-info">
            <strong>{appointment.center}</strong>
            {formatDayDate(appointment.begin, appointment.end)} de {formatHourDate(appointment.begin, appointment.end)}
          </div>
          {+appointment.status !== StatusEnum.CANCELLED ?
            <button type="button" className="button button-small" onClick={(e) => openConfirmModal(e)}>
              {t('common.appointment.cancel')}
            </button> :
            <div style={{width: '44px'}}></div>}
        </div>
        <ExpandMoreIcon fontSize="medium" className="arrow" />
      </div>
      <Collapse in={openCollapseStatus.includes(index)}>
        <div className="card-list-body">
          <div className="block-message" >
            <div className="block-message-header">{t('common.appointment.guests')}</div>
            <List className="py-0 flex-wrap">
              {appointment.guestsSum && JSON.parse('[' + appointment.guestsSum + ']')
                .map((guest: any, index: number) => {
                  return (
                    <ListItem key={index} dense={true} className="block-list icon" style={{ width: 'auto' }}>
                      <Chip
                        key={guest.id}
                        size="small"
                        className="chip-bg"
                        icon={formatGuestInvitationStatus(guest.status, guest.statusLabel)}
                        label={`${guest.name}`}
                      />
                    </ListItem>
                  )
                })}
            </List>
          </div>
          {appointment.description !== undefined && appointment.description !== '' && (
            <div className="block-message">
              <div className="block-message-header">{t('common.appointment.description')}</div>
              <div className="block-message-content">{appointment.description}</div>
            </div>
          )}
        </div>
      </Collapse>
    </div>
  )
}

const formatGuestInvitationStatus = (status: number, text: string) => {
  let icon = <AccessTime />

  switch (Number(status)) {
    case GuestStatusEnum.ACCEPTED:
      icon = <CheckCircle className="statusIcon" data-toggle={'tooltip'} />
      break
    case GuestStatusEnum.WAITING:
      icon = <AccessTime className="" />
      break
    case GuestStatusEnum.REFUSED:
      icon = <Cancel className="cancelledIcon" />
      break
    case GuestStatusEnum.PRESENT:
      icon = <CheckCircle className="success" data-toggle={'tooltip'} />
      break
    case GuestStatusEnum.ABSENT:
      icon = <Cancel className="" />
      break
  }

  return (
    <Box component="span" display="flex" alignItems="center" justifyContent="center">
      <Tooltip title={text} placement="top">
        {icon}
      </Tooltip>
    </Box>
  )
}

export default appointmentListItem

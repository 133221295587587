import React, {createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useMutation, useQuery} from 'react-query'
import {useTranslation} from 'react-i18next'

import {OnLoginContextType} from 'app/types/providers.type'
import {ICenter} from 'app/models/centers.model'
import {CenterListQuery} from 'app/api/centers.api'
import {useApp} from 'app/providers/app.provider'
import {ContractListQuery} from 'app/api/contracts.api'
import {IContract, IContractListItem} from 'app/models/contracts.model'
import Session from 'app/libraries/session.lib'
import {IAccount, IPassword} from 'app/models/account.model'
import {AccountQuery, AccountUpdateQuery, PasswordUpdateQuery} from 'app/api/accounts.api'
import {IRole} from 'app/models/role.model'
import {RoleEnum, SeverityEnum} from 'app/enums/app.enum'
import {IUser} from 'app/models/user.model'
import {ConnectionQuery} from 'app/api/connection.api'

interface IProps {
  children: ReactNode
}

const OnLoginContext = createContext<OnLoginContextType>({
  centers: undefined,
  contracts: [],
  setCenters: () => undefined,
  pendingContracts: undefined,
  account: undefined,
  accountUseQuery: undefined,
  accountUpdateUseMutation: undefined,
  passwordUpdateUseMutation: undefined,
  connectionUseMutation: undefined,
  currentEnterprise: Session.getCurrentEnterprise(),
  setCurrentEnterprise: () => undefined,
  currentRole: Session.getCurrentRole(),
  roles: [],
  currentCenter: Session.getCurrentCenter(),
  mainCenter: Session.getCurrentCenterName(),
  centerList: [],
  isFinishedChangeEnterprise: false,
  userCenter: Session.getUserCenterName(),
  userCenterId: Session.getUserCenter()
})

const OnLoginProvider = ({children}: IProps): JSX.Element => {
  const {t} = useTranslation()
  const {isConnected, throwAppException, onLogout, fromApp, setIsConnected} = useApp()

  const [centers, setCenters] = useState<ICenter[] | undefined>()
  const [contracts, setContracts] = useState<IContractListItem[]>([])
  const [pendingContracts, setPendingContracts] = useState<IContractListItem[] | undefined>()
  const [account, setAccount] = useState<IAccount | undefined>()
  const [roles, setRoles] = useState<IRole[]>([])
  const [centerList, setCenterList] = useState<string[]>([])
  const [mainCenter, setMainCenter] = useState<string>(Session.getCurrentCenterName())
  const [currentRole, setCurrentRole] = useState<RoleEnum>(Session.getCurrentRole())
  const [currentEnterprise, setCurrentEnterprise] = useState<number>(Session.getCurrentEnterprise())
  const [currentCenter, setCurrentCenter] = useState<number>(Session.getCurrentCenter())
  const [isFinishedChangeEnterprise, setIsFinishedChangeEnterprise] = useState<boolean>(false)
  const [userCenter, setUserCenter] = useState<string | undefined>(Session.getUserCenterName())
  const [userCenterId, setUserCenterId] = useState<number | undefined>(Session.getUserCenter())

  useEffect(() => {
    if (onLogout) {
      setCenters(undefined)
      setContracts([])
      setPendingContracts(undefined)
      setAccount(undefined)
      setCurrentEnterprise(0)
      setCurrentRole(RoleEnum.USER)
      setCurrentCenter(0)
      setMainCenter('')
    }
  }, [onLogout])

  useEffect(() => {
    if (currentEnterprise !== Session.getCurrentEnterprise() && currentEnterprise > 0) {
      setIsFinishedChangeEnterprise(false)
      let r = Session.getUser()?.roles.filter((r: IRole) => r.entreprise_id === currentEnterprise)
      if (r !== undefined && r.length > 0) {
        setCurrentRole(r[0].role)
        setCurrentCenter(r[0].center_id)
        setMainCenter(r[0].center_name)
        setCenterList(r[0].centers)
      }
      Session.setCurrentEnterprise(currentEnterprise)
    }
  }, [currentEnterprise])

  useEffect(() => {
    if (currentCenter !== Session.getCurrentCenter() && currentCenter > 0) {
      Session.setCurrentCenter(currentCenter)
    }
  }, [currentCenter])

  useEffect(() => {
    if (mainCenter !== Session.getCurrentCenterName()) {
      Session.setCurrentCenterName(mainCenter)
    }
  }, [mainCenter])

  useEffect(() => {
    if (!fromApp && currentRole !== Session.getCurrentRole()) {
      Session.setCurrentRole(currentRole)
    }
    if (RoleEnum.ADMIN === currentRole) {
      listContractUseQuery.mutateAsync().then()
    }

    const resource = window.location.pathname.split('/', 3)
    const mainResource = resource[1]

    const subResource = resource[2]
    if (
      (
        ['enterprise'].includes(mainResource) &&
        'ROLE_USER' === currentRole
      ) ||
      (
        undefined !== subResource &&
        'enterprise' === mainResource &&
        ['members', 'documents', 'accountingdocuments', 'contracts'].includes(subResource) &&
        'ROLE_ENTERPRISE_MANAGER' === currentRole
      )
    ) {
      window.location.href = '/home'
    }
  }, [currentRole])

  useEffect(() => {
    if (!isFinishedChangeEnterprise && currentRole === Session.getCurrentRole()) {
      if ('ROLE_ENTERPRISE_ADMIN' === currentRole) {
        listContractUseQuery.mutateAsync().then()
      }
      setIsFinishedChangeEnterprise(true)
    }
  }, [currentEnterprise, currentRole, isFinishedChangeEnterprise])

  useEffect(() => {
    if (
      !fromApp &&
      (Session.getUser() === undefined ||
        Session.getToken() === '' ||
        Session.getCurrentEnterprise() === 0 ||
        Session.getCurrentCenter() === 0 ||
        Session.getCurrentCenterName() === '') &&
      window.location.pathname.split('/', 2)[1] !== 'login' &&
      window.location.pathname.split('/', 2)[1] !== 'quotations' &&
      window.location.pathname.split('/', 2)[1] !== 'payment' &&
      window.location.pathname.split('/', 2)[1] !== 'payment_result' &&
      window.location.pathname.split('/', 2)[1] !== 'password' &&
      window.location.pathname.split('/', 2)[1] !== 'checkin'
    ) {
      window.location.href = '/login'
    } else {
      let user = Session.getUser()
      if (user !== undefined) {
        let [r] = user.roles.filter((r: IRole) => r.entreprise_id === currentEnterprise)
        if (r !== undefined) {
          setCenterList(r.centers)
        }
        setRoles(user.roles)
        setIsConnected(true)
      }

      let params: string[] = window.location.search.substring(1).split('&')
      if (params.filter((p: string) => p === 'error=403').length > 0) {
        throwAppException(403, {
          severity: SeverityEnum.ERROR,
          message: t('common.error.forbidden'),
          timeout: 10000
        })
      }
    }
  }, [fromApp])

  useEffect(() => {
    if (!onLogout) {
      const pendingContracts = contracts.filter(
        (contract: IContractListItem) => contract.status === 'Pending agreement'
      )
      setPendingContracts(pendingContracts)
    }
  }, [contracts])

  const onUpdatedContract = useCallback((updatedContract: IContract): void => {
    const _contracts = [...contracts]
    // TODO: uncomment and complete
    //_contracts.find((c: IContractListItem) => c.id === updatedContract.id)!.status = updatedContract.status
    setContracts(_contracts)
  }, [])

  const listCenterUseQuery = useQuery<ICenter[], unknown>(
    'centerList',
    async () => await CenterListQuery(),
    {
      retry: false,
      onSuccess: async (_centers: ICenter[]) => setCenters(_centers),
      onError: async (err: unknown) => {
        setCenters([])
        throwAppException(Number(err))
      },
      enabled: undefined === centers && isConnected
    }
  )

  const connectionUseMutation = useMutation<IUser, unknown, string>(
    'connection',
    async (params: string) => await ConnectionQuery(params),
    {
      onSuccess: async (_user: IUser) => {
        let [role] = _user.roles!
        Session.setUser(_user)
        Session.setToken(_user.token)
        Session.setCurrentCenterName(role.center_name)
        Session.setCurrentCenter(role.center_id)
        Session.setCurrentEnterprise(role.entreprise_id)
        Session.setCurrentRole(role.role)

        if (undefined !== _user.userCenterId) {
          Session.setUserCenter(_user.userCenterId)
        }

        if (undefined !== _user.userCenter) {
          Session.setUserCenterName(_user.userCenter)
        }

        window.location.href = '/home'
      },
      onError: async (err: unknown) => {
        throwAppException(Number(err))
      }
    }
  )

  const accountUseQuery = useQuery<IAccount, unknown>(
    ['account', Session.getUserId()],
    async () => await AccountQuery(Session.getUserId()),
    {
      retry: false,
      onSuccess: async (_account: IAccount) => setAccount(_account),
      onError: async (err: unknown) => throwAppException(Number(err)),
      enabled: undefined === account && isConnected
    }
  )

  const listContractUseQuery = useMutation<IContractListItem[], unknown>(
    'contractList',
    async () => await ContractListQuery(currentEnterprise),
    {
      onSuccess: async (_contracts: IContractListItem[]) => setContracts(_contracts),
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const accountUpdateUseMutation = useMutation<IAccount, unknown, IAccount>(
    'accountUpdate',
    async (updatedAccount: IAccount) =>
      await AccountUpdateQuery(Session.getUserId(), updatedAccount),
    {
      onSuccess: async (_account: IAccount) => {
        setAccount(_account)
        window.location.href = '/profile'
      },
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const passwordUpdateUseMutation = useMutation<IAccount, unknown, IPassword>(
    'passwordUpdate',
    async (password: IPassword) => await PasswordUpdateQuery(Session.getUserId(), password),
    {
      onSuccess: async () => {
        window.location.href = '/profile'
      },
      onError: async (err: unknown) => throwAppException(Number(err))
    }
  )

  const contextValue = useMemo(
    () => ({
      centers,
      contracts,
      setCenters,
      listCenterUseQuery,
      pendingContracts,
      account,
      accountUseQuery,
      accountUpdateUseMutation,
      passwordUpdateUseMutation,
      connectionUseMutation,
      currentEnterprise,
      setCurrentEnterprise,
      currentRole,
      roles,
      currentCenter,
      mainCenter,
      centerList,
      isFinishedChangeEnterprise,
      userCenter,
      userCenterId
    }),
    [
      centers,
      contracts,
      listCenterUseQuery,
      pendingContracts,
      account,
      accountUseQuery,
      accountUpdateUseMutation,
      passwordUpdateUseMutation,
      connectionUseMutation,
      currentEnterprise,
      setCurrentEnterprise,
      currentRole,
      roles,
      currentCenter,
      mainCenter,
      centerList,
      isFinishedChangeEnterprise,
      userCenter,
      userCenterId
    ]
  )

  return <OnLoginContext.Provider value={contextValue}>{children}</OnLoginContext.Provider>
}

export default OnLoginProvider

export const useOnLogin = (): OnLoginContextType => useContext<OnLoginContextType>(OnLoginContext)

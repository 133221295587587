import { createTheme } from '@material-ui/core/styles';
import colors from './colors.json';

const theme = createTheme({
  typography: {
    h2: {
      fontSize: '1.22rem',
      fontWeight: 500,
      '@media (max-width:959px)': {
        fontSize: '1.1rem',
      }
    },
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
        textTransform: 'none',
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: colors.backgroundLight,
          color: colors.foregroundPrimary,
          border: '1px solid',
          borderColor: colors.borderPrimary,
        },
        '&:active': {
          backgroundColor: colors.backgroundLight,
          color: colors.foregroundPrimary,
          border: '1px solid',
          borderColor: colors.borderPrimary,
        },
        '&:focus': {
          backgroundColor: colors.backgroundLight,
          color: colors.foregroundPrimary,
          border: '1px solid',
          borderColor: colors.borderPrimary,
        },
      },
    },
  },
});

export const dark_theme = createTheme({
  ...theme,
  palette: {
    type: 'dark',
    background: {
      default: colors.darkTheme.background,
    },
    primary: {
      main: colors.darkTheme.foreground,
    },
    text: {
      primary: colors.darkTheme.foreground
    },
  },
  overrides: {
    MuiToolbar: {
      root: {
        justifyContent: "space-between",
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: colors.darkTheme.background,
        borderBottom: `1px solid ${ colors.darkTheme.border}`,
      },
    },
    MuiPaper:{
      elevation1: {
        boxShadow: '0px 3px 3px -2px rgba(0,0,0,6%), 0px 3px 4px 0px rgba(0,0,0,4%), 0px 1px 8px 0px rgba(0,0,0,4%)',
      },
      root:{
        backgroundColor: colors.darkTheme.background,
      },
    },
  },
});

export const light_theme = createTheme({
  ...theme,
  palette: {
    type: 'light',
    primary: {
      main: colors.foregroundPrimary,
    },
    text: {
      primary: colors.foregroundDark
    },
  },
  overrides: {
    MuiTypography: {
      h2: {
        '&.MuiTypography-colorPrimary': {
          color: colors.foregroundDark,
        },
      },
    },
    MuiPaper:{
      elevation1: {
        boxShadow: '0px 3px 3px -2px rgba(0,0,0,6%), 0px 3px 4px 0px rgba(0,0,0,4%), 0px 1px 8px 0px rgba(0,0,0,4%)',
      },
      root:{
        backgroundColor: colors.backgroundPaperLightest,
      },
    },
    MuiToolbar: {
      root: {
        justifyContent: "space-between",
      },
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: colors.backgroundPaperLightest,
        borderBottom: `1px solid ${colors.borderLight}`,
      },
    },
  },
});

import React, { Fragment, useEffect, useState } from 'react'
import {useTranslation} from 'react-i18next'
import { CheckInQuery, ResponseCheckInQuery } from 'app/api/appointments.api'
import { GuestStatusEnum, IAppointment } from 'app/models/appointment.model'
import { Chip, Tooltip, Box, Grid, Container, Button, TextField } from '@material-ui/core'
import { AccessTime, CheckCircle, Cancel } from '@material-ui/icons';
import CssBaseline from '@material-ui/core/CssBaseline'
import 'modules/appointments/css/checkin.scss'
import InfoModal from 'modules/appointments/components/InfoModal'

const CheckIn = (props: { isDarkMode: boolean }): JSX.Element => {
  const {t} = useTranslation()
  const [token, setToken] = useState<string>('')
  const [appointment, setAppointment] = useState<IAppointment | undefined>()
  const [guestsSum, setGuestsSum] = useState<[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')

  // info modal
  const [open, setOpen] = useState<boolean>(false)

  function formatDayDate(begin: string, end: string) {
    // Create Date objects
    const beginDate = new Date(begin);
    const endDate = new Date(end);

    // Set up formatter for the date and time in French
    const dateFormatter = new Intl.DateTimeFormat('fr-FR', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    });

    // Format the date and times
    const beginFormattedDate = dateFormatter.format(beginDate);
    const endFormattedDate = dateFormatter.format(endDate);

    // Combine date and times into the final string
    return beginFormattedDate === endFormattedDate ? `${beginFormattedDate}` :
        `${beginFormattedDate} - ${endFormattedDate}`;
  }

  function formatHourDate(begin: string, end: string) {
    // Create Date objects
    const beginDate = new Date(begin);
    const endDate = new Date(end);

    const timeFormatter = new Intl.DateTimeFormat('fr-FR', {
      hour: '2-digit',
      minute: '2-digit',
    });

    const formattedBeginTime = timeFormatter.format(beginDate);
    const formattedEndTime = timeFormatter.format(endDate);

    // Combine date and times into the final string
    return `${formattedBeginTime} à ${formattedEndTime}`;
  }

  useEffect(() => {
    setIsLoading(true)
    let params = new URLSearchParams(window.location.search)
    let token = params.get('token')
    if (token !== null) {
      setToken(token)
    } else {
      window.location.href = '/login'
    }
  }, [])

  useEffect(() => {
    if (!token)
      return ;
    CheckInQuery(encodeURIComponent(token))
      .then((value) => {
        if (value == null)
          window.location.href = '/login';
        setAppointment(value)
        const guestsSum = `[${value.guestsSum}]`
        setGuestsSum(JSON.parse(guestsSum))
        setIsLoading(false)
      })
      .catch()
  }, [token])

  const onResponse = (isConfirm: boolean) => {
    ResponseCheckInQuery(encodeURIComponent(token), isConfirm ? 1 : 0, comment)
      .then(() => {
        setOpen(true);
        CheckInQuery(encodeURIComponent(token))
          .then((value) => {
            if (value == null)
              window.location.href = '/login';
            setAppointment(value)
            const guestsSum = `[${value.guestsSum}]`
            setGuestsSum(JSON.parse(guestsSum))
            setIsLoading(false)
          })
          .catch()
      })
      .catch(() => {
        window.location.href = '/login';
      })
  }
  if (!appointment)
      return <></>

  return ( isLoading ? <></> :
    <Fragment>
      <CssBaseline />
      <Container maxWidth="xs" className={props.isDarkMode ? "bkContainerLoginDark" : "bkContainerLogin"} >
        <InfoModal message={t('common.appointment.checkinDone')} open={open} onClose={() => setOpen(false)} />
        <Grid container spacing={3} className={'p-20'}>
          <Grid item xs={12} className="text-center mt-15" >
            <Grid container spacing={2} justifyContent={'center'}>
              <Grid item>
                <h3 className={'m-0'}>
                  { t('common.appointment.yourAppointment') + ' ' + appointment.organiser}
                </h3>
              </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent={'center'} className="mt-15" >
              {guestsSum
                .map((item: { id: number; name: string; status: number; statusLabel: string }) => (
                  <Grid key={item.id} item>
                    <Chip
                      key={item.id}
                        size="small"
                        className="chip-bg"
                        icon={formatGuestInvitationStatus(item.status, item.statusLabel)}
                        label={`${item.name}`}
                      />
                    </Grid>
                  ))}
            </Grid>
            <Grid container spacing={1} justifyContent={'center'} className="mt-15">
              <Grid item>
                <h3 className={'section-title m-0'}>
                  {t('common.appointment.address')}
                </h3>
                <strong>
                  <p className={'m-0'}>
                    {appointment.center}
                  </p>
                </strong>
                <strong>
                  <p className={'m-0'}>
                    {appointment.centerAddress}
                  </p>
                </strong>
              </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent={'center'} className="mt-15">
              <Grid item>
                <h3 className={'section-title m-0'}>
                  {t('common.appointment.date')}
                </h3>
                <strong>
                  <p className={'m-0'}>
                    {formatDayDate(appointment.begin, appointment.end)}
                  </p>
                </strong>
                <strong>
                  <p className={'m-0'}>
                    {formatHourDate(appointment.begin, appointment.end)}
                  </p>
                </strong>
              </Grid>
            </Grid>
            <Grid container spacing={1} justifyContent={'center'} className="mt-15">
              <Grid item>
                <h3 className={'section-title m-0'}>
                  {t('common.appointment.description')}
                </h3>
                <strong>
                  <p className={'m-0'}>
                    {appointment.description}
                  </p>
                </strong>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="text-center" >
            <Grid container spacing={2} justifyContent={'center'} className={'mb-20'}>
              <Grid item >
                <h3 className={'section-title'} >
                  {t('common.appointment.rsvp')}
                </h3>
                <TextField
                  disabled={(new Date(appointment.begin)) < new Date()}
                  multiline
                  minRows={1}
                  maxRows={5}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  style={{ width: '350px' }}
                  placeholder={t('common.appointment.facultativeMsg')} />
              </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent={'center'}>
              <Grid item>
                <Button
                  disabled={(new Date(appointment.begin)) < new Date()}
                  className="button-cancel"
                  size={'small'}
                  color={'secondary'}
                  onClick={() => onResponse(false)}>
                  {t('common.global.refuse')}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={(new Date(appointment.begin)) < new Date()}
                  fullWidth
                  className="button-confirm"
                  size={'small'}
                  disableElevation
                  type="button"
                  color={'primary'}
                  onClick={() => onResponse(true)}>
                  {t('common.global.confirm')}
                </Button>
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12} className="text-center" >
            {(new Date(appointment.begin)) < new Date() ? `(${t('common.appointment.over')})` : ""}
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  )
}

const formatGuestInvitationStatus = (status: number, text: string) => {
  let icon = <AccessTime />

  switch (Number(status)) {
    case GuestStatusEnum.ACCEPTED:
      icon = <CheckCircle className="statusIcon" data-toggle={'tooltip'} />
      break
    case GuestStatusEnum.WAITING:
      icon = <AccessTime className="" />
      break
    case GuestStatusEnum.REFUSED:
      icon = <Cancel className="cancelledIcon" />
      break
    case GuestStatusEnum.PRESENT:
      icon = <CheckCircle className="success" data-toggle={'tooltip'} />
      break
    case GuestStatusEnum.ABSENT:
      icon = <Cancel className="" />
      break
  }

  return (
    <Box component="span" display="flex" alignItems="center" justifyContent="center">
      <Tooltip title={text} placement="top">
        {icon}
      </Tooltip>
    </Box>
  )
}

export default CheckIn

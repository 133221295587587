import { checkResponse, getRequestInit, getUrl, patchRequestInit, postRequestInit } from 'app/api/api'
import { IAppointment, IAppointmentList, IAppointmentData, IGuest, IGuestData } from 'app/models/appointment.model'

export const CheckInQuery = async (token: string): Promise<IAppointment> => {
  return checkResponse(await fetch(getUrl('checkin?token=' + token), getRequestInit(false)))
}

export const ResponseCheckInQuery = async (token: string, status: number, commentary: string): Promise<void> => {
  return checkResponse(
    await fetch(getUrl(`checkin?token=` + token), patchRequestInit(true, JSON.stringify({status, commentary})))
  )
}

export const AppointmentListQuery = async (year: string, month: string): Promise<IAppointmentList> => {
  return checkResponse(
    await fetch(
      getUrl(`clientspace/appointments?year=${year}&month=${month}`),
      getRequestInit(true)
    )
  )
}

export const AppointmentCancelQuery = async (appointmentId: number): Promise<void> => {
  return checkResponse(
    await fetch(
      getUrl(`clientspace/appointments/${appointmentId}/cancel`),
      patchRequestInit(true, JSON.stringify({}))
    )
  )
}

export const AppointmentCreateQuery = async (appointmentData: IAppointmentData): Promise<void> => {
  return checkResponse(
    await fetch(getUrl('clientspace/appointments'), postRequestInit(true, JSON.stringify(appointmentData)))
  )
}

export const GuestSearchQuery = async (search: string): Promise<IGuest> => {
  return checkResponse(
    await fetch(getUrl(`clientspace/clients/guests?email=${search}`), getRequestInit(true))
  )
}

export const GuestCreateQuery = async (guest: IGuestData): Promise<IGuest> => {
  return checkResponse(
    await fetch(getUrl('clientspace/clients/guests'), postRequestInit(true, JSON.stringify(guest)))
  )
}
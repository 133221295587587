import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Tooltip,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PersonOutlineIcon from '@material-ui/icons/PersonOutline'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import BookingListCardPricerComponent from 'modules/booking/components/booking/bookingListCardPricer.component'
import { Link } from 'react-router-dom'
import { ImageSchema, MeetingSchema } from 'app/models/booking.model'
import { Carousel } from 'app/components/carousel/carousel.component'
import { useOnScreen } from 'app/components/useOnScreen.hook'
import { useOnView } from 'app/providers/onview.provider'
import { useOnLogin } from 'app/providers/onlogin.provider'

interface IProps {
  meetingRoom: MeetingSchema,
  images: ImageSchema[],
  bookingDate: string
}

const BookingListCardComponent = ({ meetingRoom, images, bookingDate }: IProps) => {
  const { t } = useTranslation()
  const { getAssets } = useOnView()
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(ref)
  const [meetingImages, setImages] = useState<ImageSchema[]>(images)
  const [fetched, setFetched] = useState<boolean>(false)
  const { currentRole, userCenterId } = useOnLogin()

  const useStyles = makeStyles({
    disabled: {
      pointerEvents: 'none',
      opacity: 0.5
    }
  })

  const classes = useStyles()
  const today = new Date().toISOString().split('T')[0]
  const isDisabled = currentRole === 'ROLE_USER' && (!meetingRoom.is_community_room || bookingDate !== today || userCenterId !== meetingRoom.centerId)

  const fetchImages = useCallback(async () => {
    const images = await getAssets?.mutateAsync({
      type: 'services',
      id: Number(meetingRoom.id)
    })
    if (images && images.length > 0) setImages(images)
  }, [meetingRoom])

  useEffect(() => {
    if (isVisible && !fetched) {
      fetchImages().then(() => setFetched(true))
    }
  }, [isVisible])
  return (
    <Grid item xs={12} sm={6} ref={ref}>
      <Card>
        <Grid container alignItems={'center'}>
          <Grid item xs={12} sm={5}>
            <Box p={2}>
              <Carousel images={meetingImages} isLoading={!fetched} />
              <BookingListCardPricerComponent
                hourlyPrice={meetingRoom.hourly_price ?? 0}
                halfdayPrice={meetingRoom.halfday_price ?? 0}
                dailyPrice={meetingRoom.daily_price ?? 0}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={7}>
            <CardContent>
              <Typography variant="h6" gutterBottom color={'primary'}>
                {meetingRoom.label}
              </Typography>
              <Typography variant="body2" style={{ marginBottom: 10 }}>
                {meetingRoom.description}
              </Typography>
              <Box display={'flex'} style={{ marginBottom: 10 }}>
                <LocationOnIcon color="primary" />
                <Box>
                  <Typography variant="subtitle2" gutterBottom>
                    {meetingRoom.centerName}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    {meetingRoom.centerAddress}
                  </Typography>
                </Box>
              </Box>
              <Box display={'flex'} alignItems={'center'} style={{ marginBottom: 30 }}>
                <PersonOutlineIcon color="primary" />
                <Typography variant="subtitle2">
                  {meetingRoom.capacity} {t('common.booking_capacity')}
                </Typography>
              </Box>
              <CardActions>
                <Tooltip title={isDisabled || !meetingRoom.isAvailable ? `${t('common.booking_not_available_meeting_room')}` : ''}>
                  <span>
                    <Link
                      to={`/booking/${meetingRoom.id}`}
                      color="primary"
                      className={`button bg-primary align-center ${isDisabled || !meetingRoom.isAvailable ? classes.disabled : ''}`}
                    >
                      {t('common.global.toBook')}
                    </Link>
                  </span>
                </Tooltip>
              </CardActions>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}

export default BookingListCardComponent

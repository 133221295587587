import React, { useEffect, useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Navbar from 'app/views/navbar.view'
import Footer from 'app/views/footer.view'
import Login from 'modules/login/views/login.view'
import Profile from 'modules/profile/views/profile.view'
import ProfileEdit from 'modules/profile/views/profile_edit.view'
import AccountingDocumentsList from 'modules/accountingDocuments/views/accountingdocuments_list.view'
import MembersList from 'modules/members/views/members_list.view'
import DocumentsList from 'modules/documents/views/documents_list.view'
import ContractsList from 'modules/contracts/views/contracts_list.view'
import ContractsDetails from 'modules/contracts/views/contracts_details.view'
import PendingContract from 'modules/contracts/views/pending_contract.view'
import QuotationsCard from 'modules/quotations/views/quotations_card.view'
import QuotationsList from 'modules/quotations/views/quotations_list.view'
import PaymentCard from 'modules/payment/views/payment_card.view'
import Credit from 'modules/credit/views/credit.view'
import PaymentResult from 'modules/payment/views/payment_result.view'
import Helpdesk from 'modules/helpdesk/views/helpdesk.view'
import HelpdeskList from 'modules/helpdesk/views/helpdesk_list.view'
import Booking from 'modules/booking/views/booking.view'
import Foodcard from 'modules/foodcard/views/foodcard.view'
import Home from 'modules/home/views/home.view'
import CheckIn from 'modules/appointments/views/checkin.view'
import AppointmentAddView from 'modules/appointments/views/appointmentAdd.view'
import DocumentsDetails from 'modules/documents/views/documents_details.view'
import ForgottenPasswordView from 'modules/login/views/ForgottenPassword.view'
import BookingList from 'modules/booking/views/booking_list.view'
import { useApp } from 'app/providers/app.provider'
import { useOnLogin } from 'app/providers/onlogin.provider'
import PendingContractButtonComponent from 'app/components/pendingContractButton.component'
import 'app/css/app.scss'
import PasswordView from "modules/login/views/password.view";
import {useAppTheme} from "app/providers/apptheme.provider";
import {IMAGES} from "app/constants/images";
import BookingRead from "modules/booking/views/booking_read.view";
import {ServiceTypeEnum} from "app/enums/booking.enum";
import AppointmentList from 'modules/appointments/views/appointments.view'

const AppView = (): JSX.Element => {
  const { isConnected, onError, setOnError, alertMessage, setAlertMessage, fromApp } = useApp()
  const { pendingContracts } = useOnLogin()

  const [appClass, setAppClass] = useState<string>('app')
  const [mustRefresh, setMustRefresh] = useState<boolean>(false)
  const {isDarkMode} = useAppTheme()
  useEffect(() => {
    let appClassTmp = 'app'
    let resource = window.location.pathname.split('/', 2)[1];
    if (
      resource === 'login' ||
      resource === 'forgotten_password' ||
      resource === 'password'||
      resource === 'checkin'
    ) {
      appClassTmp = 'app login'
    } else if (resource === 'quotations' && !isConnected) {
      appClassTmp = 'app quotations'
    } else if (resource === 'payment_result' && !isConnected) {
      appClassTmp = 'app payment_result'
    }
    if (isDarkMode) {
      appClassTmp += ' dark'
    }
    setAppClass(appClassTmp)
  }, [window.location.pathname, isDarkMode])

  useEffect(() => {
    if (mustRefresh) {
      setMustRefresh(false)
      let resource = window.location.pathname.split('/', 2)[1]
      switch (resource) {
        case 'home':
        case 'profile/edit':
        case 'profile':
        case 'helpdesk_list':
        case 'helpdesk':
          window.location.reload()
          break
        default:
          window.location.href = '/home'
      }
    }
  }, [mustRefresh])

  const onAppSnackbarClose = (): void => {
    setOnError(false)
    setAlertMessage(undefined)
  }

  return (
    <Router>
      {isConnected && <div className={isDarkMode ? 'bg dark' : 'bg'} />}
      <div className={appClass} style={{
        backgroundImage: appClass === 'app login' ? `url(${isDarkMode ? IMAGES.BG_LOGIN_DARK : IMAGES.BG_LOGIN})` : undefined
      }}>
        {!fromApp && (
          <div className="navbar dark">
            <Navbar />
          </div>
        )}
        <div className="content">
          <Routes>
            <Route path="/login" element={<Login isDarkMode={isDarkMode} />} />
            <Route path="/forgotten_password" element={<ForgottenPasswordView isDarkMode={isDarkMode} />} />
            <Route path="/helpdesk" element={<Helpdesk />} />
            <Route path="/helpdesk_list" element={<HelpdeskList />} />
            <Route path="/home" element={
              <>
                {undefined !== pendingContracts && pendingContracts.length > 0 && (
                  <PendingContractButtonComponent />
                )}
                <Home />
              </>
            } />
            <Route path="/appointments/add" element={<AppointmentAddView />} />
            <Route path="/booking/:id/read" element={<BookingRead />} />
            <Route path="/booking/:id" element={<BookingRead />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/booking-office" element={<BookingRead mode={ServiceTypeEnum.OPEN_DESKTOP} />} />
            <Route path="/booking_list" element={<BookingList />} />
            <Route path="/appointments" element={<AppointmentList />} />
            <Route path="/foodcard" element={<Foodcard />} />
            <Route path="/quotations" element={<QuotationsCard />} />
            <Route path="/password" element={<PasswordView isDarkMode={isDarkMode} />} />
            <Route path="/checkin" element={<CheckIn isDarkMode={isDarkMode}/>} />
            <Route path="/payment" element={<PaymentCard />} />
            <Route path="/credit" element={<Credit />} />
            <Route path="/payment_result" element={<PaymentResult />} />
            <Route path="/enterprise/accountingdocuments/list" element={
              <>
                {undefined !== pendingContracts && pendingContracts.length > 0 && (
                  <PendingContractButtonComponent />
                )}
                <AccountingDocumentsList individualOnly={false} />
              </>
            } />
            <Route path="/accountingdocuments/list" element={
              <>
                {undefined !== pendingContracts && pendingContracts.length > 0 && (
                  <PendingContractButtonComponent />
                )}
                <AccountingDocumentsList individualOnly={true} />
              </>
            } />
            <Route path="/enterprise/members/list" element={
              <>
                {undefined !== pendingContracts && pendingContracts.length > 0 && (
                  <PendingContractButtonComponent />
                )}
                <MembersList />
              </>
            } />
            <Route path="/enterprise/documents/list" element={
              <>
                {undefined !== pendingContracts && pendingContracts.length > 0 && (
                  <PendingContractButtonComponent />
                )}
                <DocumentsList />
              </>
            } />
            <Route path="/enterprise/documents/:id/details" element={<DocumentsDetails />} />
            <Route path="/enterprise/quotations/list" element={<QuotationsList />} />
            <Route path="/enterprise/contracts/list" element={<ContractsList />} />
            <Route path="/enterprise/contracts/:id/details" element={<ContractsDetails />} />
            <Route path="/enterprise/contracts/:id/pending" element={<PendingContract />} />
            <Route path="/profile/edit" element={<ProfileEdit />} />
            <Route path="/profile" element={
              <>
                {undefined !== pendingContracts && pendingContracts.length > 0 && (
                  <PendingContractButtonComponent />
                )}
                <Profile />
              </>
            } />
          </Routes>
        </div>
      </div>
      {onError && (
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          open={onError}
          autoHideDuration={6000}
          onClose={onAppSnackbarClose}
          message={alertMessage?.message}
          action={
            <React.Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={onAppSnackbarClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </React.Fragment>
          }
        />
      )}
      {isConnected && !fromApp && <Footer />}
    </Router>
  )
}

export default AppView

import dayjs from 'dayjs'

export interface IAppointment {
  id: number
  organiser: string
  organiserId: number
  organiserEmail: string
  staff: string
  staffId: number
  center: string
  centerAddress: string
  centerPhone: string
  type: string
  description: string
  status: string
  statusLabel: string
  cancellationDate: string
  begin: string
  end: string
  durationMin: string
  guestsSum: string
}

export interface IAppointmentListItem {
  id: number
  organiser: string
  organiserId: number
  staff: string
  staffId: number
  center: string
  type: string
  description: string
  status: string
  statusLabel: string
  cancellationDate: string
  begin: string
  end: string
  guestsSum: string|null
}

export interface IAppointmentList {
  total: number
  actual: number
  last: boolean
  items: IAppointmentListItem[]
}

export enum StatusEnum {
  SCHEDULED = 1,
  CANCELLED = 2,
  CONFIRMED = 3
}

export enum GuestStatusEnum {
  WAITING = 1,
  ACCEPTED = 2,
  REFUSED = 3,
  PRESENT = 4,
  ABSENT = 5
}

export enum AppointmentSelectMonthEnum {
  ALLMONTHS = 0,
  JANUARY = 1,
  FEBRUARY = 2,
  MARCH = 3,
  APRIL = 4,
  MAY = 5,
  JUNE = 6,
  JULY = 7,
  AUGUST = 8,
  SEPTEMBER = 9,
  OCTOBER = 10,
  NOVEMBER = 11,
  DECEMBER = 12,
}

export interface IGuestData {
  firstname: string
  lastname: string
  email: string
  mobile: string
  appointmentCenter: number
}

export interface IGuest {
  id: number
  name: string
}

export interface IAppointmentData {
  organiser: number
  center: number
  description: string
  beginDate: dayjs.Dayjs
  endDate: dayjs.Dayjs
  guests: any[]
}

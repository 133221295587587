import React, {ChangeEvent, useCallback, useEffect, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import {useApp} from 'app/providers/app.provider'
import {useOnView} from 'app/providers/onview.provider'

import AppointmentListItem from 'modules/appointments/components/appointmentListItem.component'

import 'modules/appointments/css/appointment.scss'
import {CircularProgress} from '@material-ui/core'
import { IAppointmentListItem, AppointmentSelectMonthEnum } from 'app/models/appointment.model'

const months: string[] = [
  'common.month.all_months',
  'common.month.january',
  'common.month.february',
  'common.month.march',
  'common.month.april',
  'common.month.may',
  'common.month.june',
  'common.month.july',
  'common.month.august',
  'common.month.september',
  'common.month.october',
  'common.month.november',
  'common.month.december',
]

const monthsEnum = [
  '',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12'
]

const currYear = new Date().getFullYear();
const years: string[] = [
  (currYear + 1).toString(),
  currYear.toString(),
  (currYear - 1).toString(),
  (currYear - 2).toString(),
  (currYear - 3).toString(),
  (currYear - 4).toString()
]

const AppointmentList = (): JSX.Element => {
  const {t} = useTranslation()
  const {fromApp} = useApp()
  const {listAppointmentsUseMutation} = useOnView()

  const [openCollapseStatus, setOpenCollapseStatus] = useState<number[]>([])

  const [appointments, setAppointments] = useState<IAppointmentListItem[]>([])

  const [selectedYear, setSelectedYear] = useState<string>(String(currYear))
  const [selectedMonth, setSelectedMonth] = useState<number>(AppointmentSelectMonthEnum.ALLMONTHS)

  const newAppointmentLink = useMemo<string>(
    () => (fromApp ? '/appointments/add?fromApp=1' : '/appointments/add'),
    [fromApp]
  )

  const className = useMemo<string>(
    () =>
      `card-content card-List-block flex-col ${
        appointments.length > 0 ? ' card-list-scroll' : ''
      }`,
    [appointments]
  )

  const getAppointmentList = useCallback(
    () => {
      listAppointmentsUseMutation?.mutateAsync({ year: selectedYear, month: monthsEnum[selectedMonth] })
        .then((value) => {
          setAppointments(value.items);
        });
    },
    [listAppointmentsUseMutation, selectedYear, selectedMonth]
  )

  useEffect(() => {
    getAppointmentList();
  }, [selectedYear, selectedMonth])

  const setOpen = useCallback(
    (index: number): void => {
      if (openCollapseStatus.includes(index)) {
        setOpenCollapseStatus(openCollapseStatus.filter((i: number) => i !== index))
      } else {
        setOpenCollapseStatus((openCollapseStatus) => [...openCollapseStatus, index])
      }
    },
    [openCollapseStatus]
  )

  return (
    <Paper elevation={1} className="paperHelpdesk">
      <Grid container spacing={2}>
        <Grid item xs={12} md={3} className="bkColImgBk">
          <Box component="div" px={2} py={4} className="small-y">
            {!fromApp && (
              <>
                <Typography variant="h2" gutterBottom={true}>
                  {t('common.appointment.title')}
                </Typography>
                <Divider/>
              </>
            )}
            <Box display={'flex'} flexDirection={'column'}>
              <Link to={newAppointmentLink} style={{marginBottom : '12px'}}  className={`button bg-white ${fromApp ? 'center' : ''}`}>
                {t('common.appointment.newAppointment')}
              </Link>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={9} className="rightColHelpdesk">
          <Box component="div" p={2} className="pt-0">
            <div className="myCard">
              <Grid container spacing={2} className="formListAppointment mt-0">
                <Grid item xs={12} md={3}>
                  <FormControl variant="standard" className="labelFormControl" fullWidth>
                    <Select
                      className="filter mr-xs mb-xs"
                      native
                      variant="standard"
                      value={selectedYear}
                      onChange={(
                        event: ChangeEvent<{ name?: string | undefined; value: unknown }>
                      ) => setSelectedYear(event.target.value as string)}>
                      {years.map((year: string, index: number) => {
                        return (
                          <option key={index} value={year}>
                            {year}
                          </option>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormControl variant="standard" className="labelFormControl" fullWidth>
                    <Select
                      className="filter mr-xs mb-xs"
                      native
                      variant="standard"
                      value={selectedMonth}
                      onChange={(
                        event: ChangeEvent<{ name?: string | undefined; value: unknown }>
                      ) => setSelectedMonth(Number(event.target.value))}>
                      {months.map((month: string, index: number) => {
                        return (
                          <option key={index} value={index}>
                            {t(month)}
                          </option>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {!listAppointmentsUseMutation?.isLoading && (
                <div className={className}>
                  {appointments.map(
                    (appointment: IAppointmentListItem, index: number) => {
                      return (
                        <AppointmentListItem
                          key={index}
                          appointment={appointment}
                          index={index}
                          setOpen={setOpen}
                          onChange={getAppointmentList}
                          openCollapseStatus={openCollapseStatus}  />
                      )
                    }
                  )}
                </div>
              )}
              {listAppointmentsUseMutation?.isLoading && (
                <div className="myCardLoader">
                  <CircularProgress color="secondary"/>
                </div>
              )}
            </div>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default AppointmentList

import React from 'react'
import { DialogContentText } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import { useTranslation } from 'react-i18next'

export interface IProps {
  open: boolean
  onClose: () => void
  message: string
}

const InfoModal = ({
   open,
   onClose,
   message
 }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Dialog fullWidth={true} onClose={onClose} open={open} className="ModalDelete">
      <DialogContentText className="text-center pt-15 pb-15">{message}</DialogContentText>
      <button
        type="button"
        className="button block"
        onClick={onClose}>
        {t('common.global.close')}
      </button>
    </Dialog>
  )
}

export default InfoModal

import React from 'react'
import { DialogContentText } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import { useTranslation } from 'react-i18next'
import "modules/appointments/css/checkin.scss"
import Grid from '@material-ui/core/Grid'

export interface IProps {
  open: boolean
  onConfirm: () => void
  onCancel: () => void
  message: string
}

const ConfirmModal = ({
   open,
   onConfirm,
   onCancel,
   message
 }: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Dialog fullWidth={true} onClose={onCancel} open={open} className="ModalDelete">
      <DialogContentText className="text-center pt-15 pb-15">{message}</DialogContentText>
      <Grid container>
        <button
          type="button"
          className="button cancel"
          onClick={() => onCancel()}>
          {t('common.global.cancel')}
        </button>
        <button
          type="button"
          className="button"
          onClick={() => onConfirm()}>
          {t('common.global.confirm')}
        </button>
      </Grid>
    </Dialog>
  )
}

export default ConfirmModal
